import { Menu } from "lucide-react";
import Logo from "./Logo";
import logoimg from "../../imgs/logo (1).png"

const SidebarHead = () => {
  return (
    <div className="flex items-center justify-between mt-3 px-5 py-3 border-b border-gray-200 ">
        <Logo img={logoimg} />
        <Menu className="text-gray-700 hidden lg:block" />
    </div>
  );
};

export default SidebarHead;
