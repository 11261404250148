import { Route, Routes } from "react-router-dom";
import "./App.css";
import AlertDemo from "./components/AlertDemo";
import Content from "./components/content/Content";
import SidebarNav from "./components/sidebar/SidebarNav";
import AddUser from "./components/content/AddUser";
import Dashboard from "./components/content/Dashboard";
import EditUser from "./components/content/EditUser";
import Settings from "./components/content/Settings";
import User from "./components/content/User";

function App() {
  return (
    <div className="App flex font-mont">
      <SidebarNav />
      <Routes>
        <Route path="/" element={<Content />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dashboard/users/:userId" element={<User />} />
          <Route path="adduser" element={<AddUser />} />
          <Route path="edituser" element={<EditUser />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
