import React from 'react'
import { Outlet } from 'react-router-dom'

const Content = () => {
  return (
    <div className='w-full bg-gray-100'>
        <div className='w-[90%] container px-10 py-10  mx-6 my-10 bg-white rounded-lg'>
            <Outlet/>
        </div>
    </div>
  )
}

export default Content